import { AiTwotoneSetting } from "react-icons/ai";

import './leaveApproval.css';
import { useState } from "react";
import { leaveAPI } from '../../api/leaveAPI';
import UserPcode from '../../components/userPcode';
import Spinner from '../../components/spinner';
import { toast } from 'react-toastify';

import useUserName from '../../common/hooks/useUserName';

export default function LeaveApproval() {
    const { userName } = useUserName();

    const [isProcessing, setIsProcessing] = useState(false);

    const [selectedPcode, setSelectedPcode] = useState('');
    const [fromDate, setFromDate] = useState("")
    const [toDate, setToDate] = useState("")

    const [leave, setLeave] = useState([])

    const [modalPCode, setModalPCode] = useState("");
    const [modalEmpID, setModalEmpID] = useState("");
    const [modalEmpName, setModalEmpName] = useState("");
    const [modalStartDate, setModalStartDate] = useState("");
    const [modalEndDate, setModalEndDate] = useState("");
    const [modalLeaveType_Name, setModalLeaveType_Name] = useState("");
    const [modalReason, setModalReason] = useState("");

    const [modalLeaveAutoNo, setModalLeaveAutoNo] = useState("");
    const [modalAppRejAutoNo, setModalAppRejAutoNo] = useState("");
    const [modalLevelAutoNo, setModalLevelAutoNo] = useState("");

    const [modalIsNoPay, setModalIsNoPay] = useState(false);

    const [modalApproveRejectReason, setmodalApproveRejectReason] = useState('');

    const [modalIsHalfDay, setModalIsHalfDay] = useState(false);

    const [balanceLeaves, setBalanceLeaves] = useState(0);

    const handlePreview = () => {

        if (fromDate.trim().length === 0) { toast.warn('Please select a Date From'); return; }

        if (toDate.trim().length === 0) { toast.warn('Please select a Date To'); return; }

        if (new Date(fromDate) > new Date(toDate)) { toast.warn('Invalid Date Range'); return; }

        loadLeaveDetails();
    }

    const loadLeaveDetails = () => {
        leaveAPI.getLeaveDetails(fromDate, toDate, selectedPcode).then((data) => setLeave(data));
    }

    const onChangePCode = (pcode) => {
        setLeave([]);
    }

    const openModal = (pCode, empID, empName, startDate, endDate, leaveType_Name, reason, leaveAutoNo, appRejAutoNo, levelAutoNo, noPay, leaveTypeCode,isHalfDay) => {
        setModalLeaveAutoNo(leaveAutoNo);
        setModalAppRejAutoNo(appRejAutoNo);
        setModalLevelAutoNo(levelAutoNo);
        setModalIsNoPay(noPay);

        setModalPCode(pCode);
        setModalEmpID(empID);
        setModalEmpName(empName);
        setModalStartDate(startDate);
        setModalEndDate(endDate);
        setModalLeaveType_Name(leaveType_Name);
        setModalReason(reason);
        setModalIsHalfDay(isHalfDay);
        

        setmodalApproveRejectReason('');

        setBalanceLeaves(0);

        leaveAPI.getBalanceLeaveForApproval(empID, leaveTypeCode, pCode, startDate, leaveAutoNo).
            then((data) => {
                setBalanceLeaves(data.balanceLeaves);
            });
    }

    const handleApproveReject = (transType) => {
        if (modalPCode.trim().length === 0 || modalEmpID.trim().length === 0 || modalStartDate.trim().length === 0 || modalEndDate.trim().length === 0 ||
            modalLeaveType_Name.trim().length === 0 || modalLeaveAutoNo.length === 0 || modalAppRejAutoNo.length === 0 ||
            modalLevelAutoNo.length === 0)
        { toast.warn("Please select leave details"); return; }

        if (transType === 'reject' && modalApproveRejectReason.trim().length === 0)
        { toast.warn(`Please enter ${(transType === 'approve_nopay' || transType === 'approve' ?'approval':'rejection')} reason`); return; }

        let dLeaveDays = ((new Date(modalEndDate) - new Date(modalStartDate)) / (1000 * 60 * 60 * 24)) + 1;

        if ((dLeaveDays > +balanceLeaves || modalIsNoPay) && transType === "approve") {
            if (window.confirm("Exceeds leave balance / No-Pay leave, do you want to approve as paid leave ?") === true) {
                approveRejectLeave(transType);
            }
        } else {
            if (window.confirm("Do you want to save ?") === true) {
                approveRejectLeave(transType);
            }
        }  
    }

    const approveRejectLeave = (transType) => {
        setIsProcessing(true);

        leaveAPI.approveRejectLeave(modalEmpID, modalStartDate, modalEndDate, modalLeaveType_Name, modalPCode, modalLeaveAutoNo, modalApproveRejectReason, transType,
            modalAppRejAutoNo, modalLevelAutoNo).
            then(() => {
                clearState();
                loadLeaveDetails();
                toast.success("Successfully saved");
                document.getElementById("btnModalClose").click();
            }).
            finally(() => {
                setIsProcessing(false);
            });     
    }

    const clearState = () => {
        setModalLeaveAutoNo('');
        setModalAppRejAutoNo('');
        setModalLevelAutoNo('');
        setModalIsNoPay(false);

        setModalPCode('');
        setModalEmpID('');
        setModalEmpName('');
        setModalStartDate('');
        setModalEndDate('');
        setModalLeaveType_Name('');
        setModalReason('');
        setmodalApproveRejectReason('');
        setModalIsHalfDay(false);

        setBalanceLeaves(0);
    }

    return (
        <>
            <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 p-2 rounded contentLeveApproval form__body">
                    <div className="row mt-1">
                        <div className='col-2 col-sm-2 col-md-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4  text-start'>
                        </div>
                        <div className="col-8 col-sm-8 col-md-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 text-center">
                            <h4>Manage Leave Request</h4>
                        </div>
                        <div className="col-2 col-sm-2 col-md-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 text-center"></div>
                    </div>
                    
                    <div className="row mt-2">
                        <div className='col-0 col-sm-0 col-md-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3'></div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                            <div className="input-group input-group-sm">
                                <UserPcode setSelectedPcode={setSelectedPcode} triggerOnChangePCode={onChangePCode} />
                            </div>
                        </div>
                        <div className='col-0 col-sm-0 col-md-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3'></div>
                    </div>
                    <div className="row mt-1">
                        <div className='col-0 col-sm-0 col-md-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3'></div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="input-group input-group-sm">
                                <input value={fromDate} onChange={(e) => setFromDate(e.target.value)} type="date" className="form-control" placeholder='From Date' />
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="input-group input-group-sm">
                                <input value={toDate} onChange={(e) => setToDate(e.target.value)} type="date" className="form-control" placeholder='To Date'/>
                            </div>
                        </div>
                        <div className='col-0 col-sm-0 col-md-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3'></div>
                    </div>
                    <div className="row mt-1">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-9 text-end">
                            <div className="btn-group btn-group-sm">
                                <button onClick={handlePreview} type="button" className="btn btn-success">Preview</button>
                            </div>
                        </div>
                        <div className='col-0 col-sm-0 col-md-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3'></div>
                    </div>
                    <div className='row'>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-2'>
                            <table className="table text-nowrap table-responsive-sm table-responsive-md table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Code</th>
                                        <th>Name</th>
                                        <th>Leave Date</th>
                                        <th>Leave Type</th>
                                        <th className='text-center'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        leave.map((le, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{le.empCode}</td>
                                                <td>{le.employeeName}</td>
                                                <td>{le.startDate}   -   {le.endDate}</td>
                                                <td>{le.leaveType_Name}</td>
                                                <td className='text-center'>
                                                    <button onClick={() => openModal(le.pCode, le.empCode, le.employeeName, le.startDate, le.endDate, le.leaveType_Name, le.reason, le.leaveAutoNo, le.appRejAutoNo, le.levelAutoNo, le.noPay, le.leaveTypeCode, le.isHalfDay)}
                                                        type="button" className="me-3 border border-0 text-danger" data-bs-toggle="modal"
                                                        data-bs-target="#staticBackdropp"><AiTwotoneSetting />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade " id="staticBackdropp" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header py-2">
                            <h4 className="modal-title fw-bold" id="staticBackdropLabel">Manage Leave Request</h4>
                            {isProcessing === false ?
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btnModalClose"></button>
                                :
                                <button type="button" className="btn-close d-none" data-bs-dismiss="modal" aria-label="Close" id="btnModalClose"></button>
                            }
                        </div>
                        <div className="modal-body">
                            {isProcessing === true && <div className="text-center"><Spinner /></div>}
                            <div className={isProcessing === true ? "row visibility--hidden": "row"}>
                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                                    <div className='row '>
                                        <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
                                            Company
                                        </div>
                                        <div className='col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 col-xxl-10'>
                                            <div className="input-group input-group-sm">
                                                <input value={modalPCode} type="text" className="form-control" readOnly />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-1'>
                                        <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
                                            Employee
                                        </div>
                                        <div className='col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                                            <div className="input-group input-group-sm">
                                                <input value={modalEmpID} type="text" className="form-control" readOnly />
                                            </div>
                                        </div>
                                        <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 '>
                                            <div className="input-group input-group-sm">
                                                <input value={modalEmpName} type="text" className="form-control" readOnly />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-4'>
                                        <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
                                            Start Date
                                        </div>
                                        <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                                            <div className="input-group input-group-sm">
                                                <input value={modalStartDate} type="text" className="form-control" readOnly />
                                            </div>
                                        </div>
                                        <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
                                            End Date
                                        </div>
                                        <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                                            <div className="input-group input-group-sm">
                                                <input value={modalEndDate} type="text" className="form-control" readOnly />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-1'>
                                        <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
                                            Leave Type
                                        </div>
                                        <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                                            <div className="input-group input-group-sm">
                                                <input value={modalLeaveType_Name} type="text" className="form-control" readOnly />
                                            </div>
                                        </div>
                                    </div>


                                    <div className='row mt-1'>
                                        <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
                                            Reason
                                        </div>
                                        <div className='col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 col-xxl-10'>
                                            <div className="input-group input-group-sm">
                                                <input value={modalReason} type="text" className="form-control" readOnly />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-1 text-danger fw-bold'>
                                        <div className='col-3 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
                                            No-Pay
                                        </div>
                                        <div className='col-3 col-sm-3 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                                            {modalIsNoPay === true ? "Yes": "No"}
                                        </div>
                                        <div className='col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                                            Balance leaves : {balanceLeaves}
                                        </div>
                                    </div>
                                    <div className='row mt-1 text-danger fw-bold'>
                                        <div className='col-3 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
                                            Half Day
                                        </div>
                                        <div className='col-3 col-sm-3 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                                            {modalIsHalfDay === true ? "Yes" : "No"}
                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2 small'>
                                            Approval / Rejection Reason
                                        </div>
                                        <div className='col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 col-xxl-10'>
                                            <div className="input-group input-group-sm">
                                                <textarea maxLength="200" className="form-control" value={modalApproveRejectReason}
                                                    onChange={(e) => setmodalApproveRejectReason(e.target.value)}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                                            <button type="button" className="btn btn-success form-control btn-sm mt-1" onClick={() => handleApproveReject('approve')}><span className="small">Approve as Paid Leave</span></button>
                                        </div>
                                        <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                                            <button type="button" className="btn btn-primary form-control btn-sm mt-1" onClick={() => handleApproveReject('approve_nopay')}><span className="small">Approve as No Pay</span></button>
                                        </div>
                                        <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                                            <button type="button" className="btn btn-danger form-control btn-sm mt-1" onClick={() => handleApproveReject('reject')}><span className="small">Reject</span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}