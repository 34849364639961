import { useState } from "react";
import { leaveAPI } from '../../api/leaveAPI';
import UserPcode from '../../components/userPcode';
import { toast } from 'react-toastify';
import Spinner from "../../components/spinner/spinner";

export default function AllLeaveDetails() {

    const [isProcessing, setIsProcessing] = useState(false);

    const [selectedPcode, setSelectedPcode] = useState('');
    const [fromDate, setFromDate] = useState("")
    const [toDate, setToDate] = useState("")

    const [empLeave, setEmpLeave] = useState([])

    const handlePreview = () => {

        if (fromDate.trim().length === 0) { toast.warn('Please select a Date From'); return; }

        if (toDate.trim().length === 0) { toast.warn('Please select a Date To'); return; }

        if (new Date(fromDate) > new Date(toDate)) { toast.warn('Invalid Date Range'); return; }

        setIsProcessing(true);

        loadEmployeeLeaveDetails();
    }

    const loadEmployeeLeaveDetails = () => {
        leaveAPI.getAllEmployeeLeaveDetails(fromDate, toDate, selectedPcode).then((data) => setEmpLeave(data)).finally(() => setIsProcessing(false));
    }

    const onChangePCode = (pcode) => { }

    return (
        <>
            <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 p-2 rounded contentLeveApproval form__body">
                    <div className="row mt-1">
                        <div className='col-2 col-sm-2 col-md-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4  text-start'></div>
                        <div className="col-8 col-sm-8 col-md-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 text-center">
                            <h4>All Approved Leaves</h4>
                        </div>
                        <div className="col-2 col-sm-2 col-md-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 text-center"></div>
                    </div>

                    <div className="row mt-2">
                        <div className='col-0 col-sm-0 col-md-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3'></div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                            <div className="input-group input-group-sm">
                                <UserPcode setSelectedPcode={setSelectedPcode} triggerOnChangePCode={onChangePCode} />
                            </div>
                        </div>
                        <div className='col-0 col-sm-0 col-md-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3'></div>
                    </div>
                    <div className="row mt-1">
                        <div className='col-0 col-sm-0 col-md-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3'></div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="input-group input-group-sm">
                                <input value={fromDate} onChange={(e) => setFromDate(e.target.value)} type="date" className="form-control" placeholder='From Date' />
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="input-group input-group-sm">
                                <input value={toDate} onChange={(e) => setToDate(e.target.value)} type="date" className="form-control" placeholder='To Date' />
                            </div>
                        </div>
                        <div className='col-0 col-sm-0 col-md-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3'></div>
                    </div>
                    <div className="row mt-1">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-9 text-end">
                            <div className="btn-group btn-group-sm">
                                <button onClick={handlePreview} type="button" className="btn btn-success">Preview</button>
                            </div>
                        </div>
                        <div className='col-0 col-sm-0 col-md-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3'></div>
                    </div>
                    <div className="row">
                        <div className='row'>
                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-2 table-responsive'>
                                {
                                    isProcessing === true ?
                                        <div className="text-center"><Spinner /></div> 
                                        :
                                        <table className="table text-nowrap">
                                            <thead className="thead--color">
                                                <tr>
                                                    <th>#</th>
                                                    <th>Employee</th>
                                                    <th>Leave Date</th>
                                                    <th>Leave Type</th>
                                                    <th>Leave Reason</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    empLeave.map((le, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{`(${le.employeeCode}) ${le.employeeName}`}</td>
                                                            <td>{le.startDate} - {le.endDate}</td>
                                                            <td>{le.leaveTypeName}</td>
                                                            <td>{le.reason}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}