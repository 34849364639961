import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from "react";
import { usersAPI } from '../../api/usersAPI';
import { Routes, Route } from 'react-router-dom';

import './navigation.css';

import LeaveApproval from '../../pages/leaveApproval';
import EmployeeLeaveDetails from '../../pages/employeeLeaveDetails'
import LeaveRequest from '../../components/modal/leaveRequest';
import AllLeaveDetails from '../../pages/allLeaveDetails';
import AllPendingLeaves from '../../pages/allPendingLeaves';

export default function Navigation({ logOut }) {
    let isFirstRender = useRef(true);

    const [isAllowLeaveReq, setIsAllowLeaveReq] = useState(false);
    const [isAllowLeaveApproval, setIsAllowLeaveApproval] = useState(false);
    const [isAllowEmployeeLeaveDetails, setIsAllowEmployeeLeaveDetails] = useState(false);

    useEffect(() => {
        if (isFirstRender) {
            isFirstRender.current = false;

            usersAPI.getValidMenus().then((data) => {
                if (data[0]) {
                    console.log(data[0].isMobLeaveReq)
                    setIsAllowLeaveReq(data[0].isMobLeaveReq);
                    setIsAllowLeaveApproval(data[0].isMobLeaveApp)
                    setIsAllowEmployeeLeaveDetails(data[0].isMobLeaveReq)
                }
            });
        }
    }, [])

    const NavBar = () => {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <div className="col-1 col-sm-1 col-md-3 col-lg-3 col-xl-3 col-xxl-3"></div>
                        <div className="col-10 col-sm-10 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            {isAllowEmployeeLeaveDetails === true &&
                                <div className="row mt-1">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                        <Link to="/mainmenu/employeeleaveDetails">
                                            <button type="button" className="form-control btn btn-primary ">My Leave Details</button>
                                        </Link>
                                    </div>
                                </div>
                            }
                            {isAllowLeaveReq === true &&
                                <div className="row mt-1">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                        <LeaveRequest />
                                    </div>
                                </div>
                            }
                            {isAllowLeaveApproval === true &&
                                <div className="row mt-1">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                        <Link to="/mainmenu/leaveapproval">
                                            <button type="button" className="form-control btn btn-primary">Manage Leave Request</button>
                                        </Link>
                                    </div>
                                </div>
                            }
                            {isAllowLeaveApproval === true &&
                                <div className="row mt-1">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                        <Link to="/mainmenu/allleavedetails">
                                            <button type="button" className="form-control btn btn-success">All Approved Leaves</button>
                                        </Link>
                                    </div>
                                </div>
                            }
                            {isAllowLeaveApproval === true &&
                                <div className="row mt-1">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                        <Link to="/mainmenu/allpendingleaves">
                                            <button type="button" className="form-control btn btn-secondary">All Pending Leaves</button>
                                        </Link>
                                    </div>
                                </div>
                            }
                            <div className="row mt-1">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                    <button type="button" className="form-control btn btn-danger" onClick={logOut}>Sign Out</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-1 col-sm-1 col-md-3 col-lg-3 col-xl-3 col-xxl-3"></div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <Routes>
                <Route path={"*"} element={<NavBar />}></Route>
                {isAllowLeaveApproval === true && <Route path={"/leaveapproval"} element={<LeaveApproval />}></Route>}

                {isAllowEmployeeLeaveDetails === true && <Route path={"/employeeleaveDetails"} element={<EmployeeLeaveDetails />}></Route>}
                {isAllowEmployeeLeaveDetails === true && <Route path={"/allleavedetails"} element={<AllLeaveDetails />}></Route>}
                {isAllowEmployeeLeaveDetails === true && <Route path={"/allpendingleaves"} element={<AllPendingLeaves />}></Route>}
            </Routes>
        </>
    );
}